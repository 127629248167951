import { ActionTree as ActionTreeVue, createStore, Plugin, StoreOptions } from "vuex";
import { api } from "@/api";
import { GameId, UserData } from "./types/user";
import {
  ActionContext as ExtActionContext,
  Store as ExtStore,
  TypedGetters,
} from "./typesafe-store";
import State from "./store/state";
import ApiStorePlugin from "@/store/api-plugin";
import * as BootstrapModule from "@/store/bootstrap";
import * as ModalsModule from "@/store/modals";
import * as UIModule from "@/store/ui";
import * as Settings from "@/store/settings";
import * as CreateTicket from "@/store/create-ticket";
import * as Draws from "@/store/draws";
import * as CurrentTicket from "@/store/current-ticket";
import * as Doubling from "@/store/doubling";
import * as Information from "@/store/information";
import * as SessionModule from "@/store/session";
import * as UserDataModule from "@/store/user";
import * as Bonus from "@/store/bonus";
import * as Sounds from "@/store/sounds";
import * as Promo from "@/store/promo";
import { getUIVersion, UIVersion } from "./version";
import { resolveLocale } from "./i18n";
import DESIGN_TYPE from "@/main";
import { CLOSE_DOUBLING_AFTER_LOST_TIMEOUT, OPEN_BOX_TIMEOUT } from "@/constants";
import { UpdateTicket } from "@/types/tickets";

export const mutations = {
  setCurrentGameId(state: State, game: GameId) {
    state.currentGameId = game;
  },
  updateUserLanguage(state: State, lenguage: string) {
    state.userData.language = lenguage;
  },
  setIsGuest(state: State, value: boolean) {
    state.isGuest = value;
  },
  setUIVersion(state: State, version: UIVersion) {
    state.uiVersion = version;
  },
  setConnected(state: State, value: boolean) {
    state.connected = value;
    if (value) {
      state.offline = false;
    }
  },
  updateBalanceUserData(state: State, newBalance: number) {
    const isDoubling = state.doubling.isOn;
    const isFruitMode = state.userData.settings.mevaho_mode === "fruit";
    const isLastGame = state.doubling.countGame === 5;
    const updateDelay =
      isLastGame && isDoubling && isFruitMode
        ? OPEN_BOX_TIMEOUT + CLOSE_DOUBLING_AFTER_LOST_TIMEOUT
        : 0;

    window.setTimeout(() => {
      state.userData = {
        ...state.userData,
        balance: newBalance,
      };
    }, updateDelay);
  },
  setClosedSession(state: State, value: boolean) {
    state.closedSession = value;
  },
  setLastTicket(state: State, ticket: UpdateTicket) {
    state.lastTicket = ticket;
  },
  ...ModalsModule.mutations,
  ...UIModule.mutations,
  ...Settings.mutations,
  ...CreateTicket.mutations,
  ...CurrentTicket.mutations,
  ...Draws.mutations,
  ...Doubling.mutations,
  ...Information.mutations,
  ...SessionModule.mutations,
  ...UserDataModule.mutations,
  ...Bonus.mutations,
  ...Sounds.mutations,
  ...Promo.mutations,
};

export const actions = {
  register({ commit, state, dispatch }: ACtx) {
    window.parent.postMessage("register", "*");
    if (!state.supportPostMessage[DESIGN_TYPE]) {
      dispatch("toggleModal", "authorization");
    }
  },
  closeSession({ commit, state, dispatch }: ACtx) {
    commit("setClosedSession", true);
    commit("setControlDisabled", true);
    window.parent.postMessage("closeMevaho", "*");
  },
  disconnectHappens({ commit, state, dispatch }: ACtx, code: number) {
    console.log(`Got disconnect ${code}`);
    // setting connected to false will block all bets
    commit("setConnected", false);
    // return if session closed by system
    if (state.closedSession) {
      return;
    }

    // try reconnect if code is not 1000 (normal socket close code)
    if (code !== 1000) {
      dispatch("openModal", "connection");
      api
        .reconnect()
        .then(async () => {
          console.log("Re-connected");
          commit("setConnected", true);
          try {
            await store.dispatch("bootstrapAfterReconnect");
          } catch (e) {
            console.log(e);
          }
        })
        .catch(() => {
          dispatch("offlineHappens");
        })
        .finally(() => {
          dispatch("closeModal", "connection");
        });
    }
  },
  offlineHappens({ commit, state, dispatch }: ACtx) {
    // this will show OfflineModal component
    state.offline = true;
  },
  updateUIVersion({ state, commit }: ACtx) {
    const version = getUIVersion();
    const oldVersion = state.uiVersion;
    // update UI version and set required css classes
    if (state.uiVersion !== version) {
      console.debug(`Use ${version} version`);
      commit("setUIVersion", version);
      // set only mobile/desktop classes
      const h = document.querySelector("html")!;
      h.classList.remove("desktop", "mobile");
      if (version === "desktop") {
        h.classList.add("desktop");
      } else if (["adaptive", "mobile"].includes(version)) {
        h.classList.add("mobile");
      }
      // reset routes; dont reset at startup and on mobile/adaptive version changes, they has same routes
      // const tmp: UIVersion[] = ["adaptive", "mobile"];
      // const hasSameRoutes = oldVersion && tmp.includes(version) && tmp.includes(oldVersion);
      // if (oldVersion && !hasSameRoutes) {
      //   resetRouter(version);
      // }
    }
  },
  // updateSessionInfo({ commit, state, dispatch }: ACtx, data: SessionInfoUpdate) {
  //   // система на тех-работах, показываем оповещение если игра уже загружена
  //   // показ модалки о тех-работах при ошибке загрузки в bootstrap
  //   if (!data.hasOwnProperty("maintenance")) return;
  //   const oldMaintenance = state.maintenanceMode;
  //   commit("setMaintenanceMode", data.maintenance);
  //   if (state.maintenanceMode) {
  //     console.warn("Maintenance on");
  //     if (state.ui.isAppLoaded) {
  //       dispatch("toggleToast", "maintenance");
  //     }
  //   } else {
  //     if (oldMaintenance) {
  //       console.warn("Maintenance off");
  //       // скрываем оповещение, если оно висело
  //       dispatch("closeToast", "maintenance");
  //     }
  //   }
  // },
  ...BootstrapModule.actions,
  ...ModalsModule.actions,
  ...UIModule.actions,
  ...Settings.actions,
  ...CreateTicket.actions,
  ...CurrentTicket.actions,
  ...Draws.actions,
  ...Doubling.actions,
  ...Information.actions,
  ...SessionModule.actions,
  ...UserDataModule.actions,
  ...Bonus.actions,
  ...Sounds.actions,
  ...Promo.actions,
};

const getters = {
  isOnline(state: State): boolean {
    return state.connected;
  },
  uiLanguage(state: State): string {
    return resolveLocale(state.userData.language, state.userData.fallback_language);
  },
  ...ModalsModule.getters,
  ...UIModule.getters,
  ...Settings.getters,
  ...CreateTicket.getters,
  ...CurrentTicket.getters,
  ...Draws.getters,
  ...Doubling.getters,
  ...Information.getters,
  ...SessionModule.getters,
  ...UserDataModule.getters,
  ...Bonus.getters,
  ...Sounds.getters,
  ...Promo.getters,
};

export const storeOpts: StoreOptions<State> = {
  plugins: [ApiStorePlugin] as Plugin<any>[],
  state: {
    currentGameId: "loto-mevaho-5",
    connected: false,
    offline: false,
    closedSession: false,
    resizeTrigger: 0,
    userData: UserDataModule.state,
    isGuest: false,
    showMenu: false,
    modals: ModalsModule.state,
    ui: UIModule.state,
    settings: Settings.state,
    createTicket: CreateTicket.state,
    currentTicket: CurrentTicket.state,
    lastTicket: undefined,
    draws: Draws.state,
    session: SessionModule.state,
    supportPostMessage: {
      lotosport: true,
    },
    doubling: Doubling.state,
    bonus: Bonus.state,
    information: Information.state,
    sounds: Sounds.state,
    promo: Promo.state,
  },
  mutations,
  actions: actions as ActionTreeVue<State, State>,
  getters: getters,
};

export type TypedStore = ExtStore<State, State, typeof mutations, typeof actions, typeof getters>;
export type ACtx = ExtActionContext<State, State, typeof mutations, any, typeof getters>;
export interface Getters extends TypedGetters<State, typeof getters> {}

export const store = createStore<State>({
  ...storeOpts,
});

// export const store = new Vuex.Store<State>(storeOpts) as TypedStore;
export default store;
