import store, { ACtx } from "@/store";
import State from "./state";
import { SessionInfoUpdate } from "@/types/session";

export type SessionState = SessionInfoUpdate;

export const state: SessionState = {
  sessionID: "",
  betstop: false,
};

export const mutations = {
  setSessionId(state: State, id: string) {
    state.session.sessionID = id;
  },
  setBetstop(state: State, value: boolean) {
    state.session.betstop = value;
  },
};

export const actions = {
  updateSessionInfo({ commit, state, dispatch }: ACtx, data: SessionInfoUpdate) {
    if (data.sessionID) {
      commit("setSessionId", data.sessionID);
    }

    if (data.betstop !== undefined) {
      commit("setBetstop", data.betstop);
      if (data.betstop) {
        commit("setAutoGame", false);
        dispatch("toggleModal", "betstop");
      } else {
        dispatch("closeModal", "betstop");
      }
    }
  },
};

export const getters = {};
