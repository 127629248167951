
import { defineComponent, PropType } from "vue";
import store from "@/store";
import { AudioUtils } from "@/utils";

export default defineComponent({
  name: "auto-game",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    bonus: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    controlDisabled(): boolean {
      return store.state.ui.controlDisabled;
    },
    isDoublingOn(): boolean {
      return store.state.doubling.isOn;
    },
    autoGame(): boolean {
      return store.state.ui.autoGame;
    },
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    isBonusGame(): boolean {
      return store.state.bonus.isOn;
    },
    disconnected(): boolean {
      return !store.state.connected;
    },
    promoIsOn(): boolean {
      return store.state.ui.promoIsOn;
    },
  },
  methods: {
    changeChecked() {
      this.playAudio();
      store.commit("setAutoGame", !this.autoGame);
    },
    playAudio() {
      if (this.autoGame) {
        AudioUtils.playSound("stop-autoplay", 0);
      } else {
        AudioUtils.playSound("launch-autoplay-button", 0);
      }
    },
    startAutogame() {
      if (this.autoGame == true) {
        store.dispatch("makeTicket");
      }
    },
  },
});
