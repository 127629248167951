import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ModalRefresh = _resolveComponent("ModalRefresh")!
  const _component_ModalRulesInfo = _resolveComponent("ModalRulesInfo")!
  const _component_ModalDraws = _resolveComponent("ModalDraws")!
  const _component_ModalConnection = _resolveComponent("ModalConnection")!
  const _component_ModalAuthorization = _resolveComponent("ModalAuthorization")!
  const _component_ModalReplenish = _resolveComponent("ModalReplenish")!
  const _component_ModalReload = _resolveComponent("ModalReload")!
  const _component_ModalDoubling = _resolveComponent("ModalDoubling")!
  const _component_ModalAutodoubling = _resolveComponent("ModalAutodoubling")!
  const _component_ModalPayments = _resolveComponent("ModalPayments")!
  const _component_ModalExpired = _resolveComponent("ModalExpired")!
  const _component_ModalBetstop = _resolveComponent("ModalBetstop")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ModalRefresh, { open: _ctx.showModalRefresh }, null, 8, ["open"]),
    _createVNode(_component_ModalRulesInfo, { open: _ctx.showModalRules }, null, 8, ["open"]),
    _createVNode(_component_ModalDraws, { open: _ctx.showModalDraws }, null, 8, ["open"]),
    _createVNode(_component_ModalConnection, { open: _ctx.showModalConnection }, null, 8, ["open"]),
    _createVNode(_component_ModalAuthorization, { open: _ctx.showModalAuthorization }, null, 8, ["open"]),
    _createVNode(_component_ModalReplenish, { open: _ctx.showModalReplenish }, null, 8, ["open"]),
    _createVNode(_component_ModalReload, { open: _ctx.showModalReload }, null, 8, ["open"]),
    _createVNode(_component_ModalDoubling, { open: _ctx.showModalDoubling }, null, 8, ["open"]),
    _createVNode(_component_ModalAutodoubling, { open: _ctx.showModalAutodoubling }, null, 8, ["open"]),
    _createVNode(_component_ModalPayments, { open: _ctx.showModalPayments }, null, 8, ["open"]),
    _createVNode(_component_ModalExpired, { open: _ctx.showModalExpired }, null, 8, ["open"]),
    _createVNode(_component_ModalBetstop, { open: _ctx.showModalBetstop }, null, 8, ["open"])
  ], 64))
}