
import { defineComponent } from "vue";
import store from "@/store";
import BurgerModal from "@/components/Modals/BurgerModal.vue";
import MyButton from "@/components/UI/MyButton.vue";
import CountdownTimer from "@/components/CountdownTimer.vue";
import CurrencyIcon from "@/components/CurrencyIcon.vue";
import { UpdateTicket } from "@/types/tickets";
import router from "@/router";

export default defineComponent({
  name: "modal-doubling",
  components: { CurrencyIcon, CountdownTimer, MyButton, BurgerModal },
  data() {
    return {};
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isDesktop(): boolean {
      return store.state.uiVersion === "desktop";
    },
    confirmDelay(): number {
      return store.state.doubling.confirmTimeout;
    },
    lastPayoutCalc(): number {
      return store.state.ui.lastPayout / 50;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    isFruitMode(): boolean {
      return store.state.userData.settings.mevaho_mode === "fruit";
    },
  },
  methods: {
    closeModal() {
      store.commit("setLastPayout", 0);
      this.closeModalAuto();
    },
    closeModalAuto() {
      store.dispatch("toggleModal", "doubling");
      store.dispatch("closeDoublingGame");
    },
    apply() {
      store.dispatch("toggleModal", "doubling");
      store.commit("setIsTakePayout", true);
      if (this.isFruitMode) {
        router.push("/doubling");
        store.dispatch("startDoublingTimer");
      } else {
        store.dispatch("applyDoublingGame");
      }
    },
  },
  // watch: {
  //   "$store.state.currentTicket": function (n: UpdateTicket | undefined) {
  //     if (n && n.payout && n.payout > 0) {
  //       this.payout = n.payout / 50;
  //     }
  //   },
  // },
});
