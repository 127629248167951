import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CombinationItem = _resolveComponent("CombinationItem")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!

  return (_ctx.uiVersion === 'desktop')
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["combination-group", {
      '--disabled': _ctx.isBetstop || _ctx.isControlDisabled || _ctx.isMakeTicketDisabled || _ctx.disconnected,
    }])
      }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinations, (value, idx) => {
          return (_openBlock(), _createBlock(_component_CombinationItem, {
            value: value,
            glow: value === _ctx.combination,
            onClick: ($event: any) => (_ctx.setCombination(value)),
            id: value
          }, null, 8, ["value", "glow", "onClick", "id"]))
        }), 256))
      ], 2))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        class: _normalizeClass(["combination-mobile", { '--disabled': _ctx.isBetstop }])
      }, [
        _createVNode(_component_swiper, {
          "space-between": 20,
          direction: 'horizontal',
          class: "mySwiper",
          "initial-slide": 0,
          slidesPerView: 'auto',
          "centered-slides": true
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.combinations, (value, idx) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, null, {
                default: _withCtx(() => [
                  _createVNode(_component_CombinationItem, {
                    onClick: ($event: any) => (_ctx.setCombination(value)),
                    value: value,
                    glow: value === _ctx.combination
                  }, null, 8, ["onClick", "value", "glow"])
                ]),
                _: 2
              }, 1024))
            }), 256))
          ]),
          _: 1
        })
      ], 2))
}