
import { defineComponent } from "vue";
import MainContainer from "@/components/MainContainer.vue";
import MenuWrapper from "@/components/MenuWrapper.vue";
import DoublingHeader from "@/components/DoublingHeader.vue";
import PurchasePanel from "@/components/PurchasePanel.vue";
import InputNice from "@/components/UI/InputNice.vue";
import store from "@/store";
import MobileHeader from "@/components/Mobile/MobileHeader.vue";
import DoublingBoxes from "@/components/DoublingBoxes.vue";
import DoublingBoxesSilkWay from "@/components/DoublingBoxesSilkWay.vue";
import DoublingBoxesPamir from "@/components/DoublingBoxesPamir.vue";
import { OPEN_BOX_TIMEOUT } from "@/constants";
import ControlPanel from "@/components/Panels/ControlPanel.vue";
import LastBoxesRow from "@/components/Mobile/LastBoxesRow.vue";
import InputFlame from "@/components/UI/InputFlame.vue";

export default defineComponent({
  name: "doubling-mobile",
  components: {
    InputFlame,
    ControlPanel,
    DoublingBoxes,
    MobileHeader,
    InputNice,
    PurchasePanel,
    DoublingHeader,
    MenuWrapper,
    MainContainer,
    LastBoxesRow,
    DoublingBoxesSilkWay,
    DoublingBoxesPamir,
  },
  data() {
    return {
      showPayout: false,
      showText: true,
    };
  },
  computed: {
    lastPayout(): number {
      return store.state.ui.lastPayout;
    },
    expectedWinnings(): number[] {
      return store.state.doubling.expectedWinnings;
    },
    stepCount(): number {
      return store.state.doubling.countGame;
    },
    isBetstop(): boolean {
      return store.state.session.betstop;
    },
    hasBigNumber(): boolean {
      return !!this.expectedWinnings.find((number) => String(number).length > 6);
    },
    fireproof(): number | undefined {
      const fireproof = store.state.settings.doubling_game.fireproof_stage;
      return fireproof.enabled ? fireproof.game_number : undefined;
    },
    isKissaho(): boolean {
      return store.state.currentGameId === "loto-kissaho";
    },
    isSilkWay(): boolean {
      return store.state.currentGameId === "loto-silk-way";
    },
    isPamirLegends(): boolean {
      return store.state.currentGameId === "loto-pamir-legends";
    },
    isPearl(): boolean {
      return store.state.currentGameId === "loto-pearl";
    },
  },
  methods: {
    showTitle({ isOn, short }: { isOn: boolean; short: boolean }) {
      const delayAnim = short ? 0 : OPEN_BOX_TIMEOUT;
      if (isOn) {
        this.showText = false;
        setTimeout(() => {
          this.showPayout = true;
        }, delayAnim);
      } else {
        this.showPayout = false;
        this.showText = true;
      }
    },
  },
});
