import store, { ACtx } from "@/store";
import State from "./state";

type Modals =
  | "refresh"
  | "currency"
  | "maintenance"
  | "rules"
  | "draws"
  | "connection"
  | "replenish"
  | "reload"
  | "doubling"
  | "autodoubling"
  | "payments"
  | "betstop"
  | "expired"
  | "authorization"
  | "promofailed"
  | "promo";

export type ModalsState = {
  [id in Modals]: boolean;
};

export const state: ModalsState = {
  refresh: false,
  currency: false,
  maintenance: false,
  rules: false,
  draws: false,
  connection: false,
  authorization: false,
  replenish: false,
  reload: false,
  doubling: false,
  autodoubling: false,
  expired: false,
  payments: false,
  betstop: false,
  promofailed: false,
  promo: false,
};

export const mutations = {
  toggleModal(state: State, data: { value: boolean; modal: Modals }) {
    state.modals[data.modal] = data.value;
  },
  closeAllModals(state: State) {
    (Object.keys(state.modals) as Modals[]).forEach((id) => (state.modals[id] = false));
  },
  closeModal(state: State, modal: Modals) {
    state.modals[modal] = false;
  },
};

export const actions = {
  async toggleModal({ state, commit, dispatch, getters }: ACtx, modal: Modals) {
    let newValue = !state.modals[modal];
    // if (getters.isModalOpened) {
    //   await dispatch("closeAllModals");
    // }
    if (!state.modals.betstop) {
      await dispatch("closeAllModals");
      commit("toggleModal", { value: newValue, modal });
    }
  },
  closeAllModals({ commit }: ACtx) {
    return new Promise<void>((resolve, reject) => {
      setTimeout(() => {
        commit("closeAllModals");
        commit("setShowMenu", false);
        resolve();
      }, 50);
    });
  },
  closeModal({ state, commit }: ACtx, modal: Modals) {
    commit("closeModal", modal);
  },
  async openModal({ state, commit, dispatch }: ACtx, modal: Modals) {
    await dispatch("closeAllModals");
    commit("toggleModal", { value: true, modal });
  },
};

export const getters = {
  isModalOpened(state: State): boolean {
    return Object.values(state.modals).filter((el) => el).length > 0;
  },
  isModalMessageOpened(state: State): boolean {
    return (
      state.modals.doubling ||
      state.modals.autodoubling ||
      state.modals.connection ||
      state.modals.replenish ||
      state.modals.authorization ||
      state.modals.reload
    );
  },
};
